import React, { useCallback } from 'react';
import { Avatar, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, } from '@mui/material';
import KeyIcon from '@mui/icons-material/VpnKey';
import { useNavigate } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import WebApp from '@twa-dev/sdk';
import CropFreeIcon from '@mui/icons-material/CropFree';

import QRCode from 'qrcode';
import '@dapp/assets/css/profile.css';
import { getWallets } from '@dapp/services/indexedDB';
import { web3GetBalance } from '@dapp/services/web3';
//import { Transak } from '@transak/transak-sdk';



WebApp.ready();

function Profile() {
  const navigate = useNavigate();
  const [walletDefault, setWalletDefault] = React.useState(null);
  const [qrCodeUrl, setQrCodeUrl] = React.useState(null);
  const [balance, setBalance] = React.useState(0);
  const [showPrivateKey, setShowPrivateKey] = React.useState(false);



  // function openTransak() {

  //   const settings = {
  //     apiKey: 'cf5868eb-a8bb-45c8-a2db-4309e5f8b412',
  //     environment: 'STAGING',
  //     defaultCryptoCurrency: 'WETH',
  //     colorMode: "DARK",
  //     hostURL: window.location.origin,
  //     hideMenu: true,
  //     walletAddress: walletDefault.address,
  //     defaultNetwork: "Blast Mainnet",
  //     network: "Blast Mainnet",
  //     exchangeScreenTitle: "Blast Roullete",
  //     fiatAmount: 10,
  //   }

  //   const transak = new Transak(settings);

  //   transak.init();



  //   // To get all the events
  //   transak.on(transak.ALL_EVENTS, (data) => {
  //     console.log(data)
  //   });

  //   // This will trigger when the user closed the widget
  //   transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (eventData) => {
  //     console.log(eventData);
  //     transak.close();
  //   });

  //   // This will trigger when the user marks payment is made.
  //   transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
  //     console.log(orderData);
  //     window.alert("Payment Success")
  //     transak.close();
  //   });
  // }


  const handlenBackButtonClick = useCallback(() => {
    navigate("/home")
  }, [navigate]);


  const copyToClipboard = useCallback((e) => {
    navigator.clipboard.writeText(e).then(() => {
      WebApp?.showAlert("copied to clipboard");
    }).catch(() => {
      WebApp?.showAlert("Failed to copy");
    });
  }, []);


  const readQRCode = () => {
    WebApp.showScanQrPopup((e) => {
      console.log(e)
    });
  }



  const getBalance = async (address) => {
    try {
      const { status, balance, message } = await web3GetBalance("BLAST", "WETH", address);
      if (status !== 200) {
        WebApp.showAlert(message);
      }
      setBalance(balance || 0);
    } catch (error) {
      WebApp.showAlert(error.message);
    }
  }

  const handleShowPrivateKey = () => {
    WebApp.showConfirm("Do not share your private key. Do you still want to export it?", ((e) => {
      if (e) {
        setShowPrivateKey(true);
      }
    }));
  }


  React.useEffect(() => {

    WebApp.setHeaderColor('#310101');

    if (WebApp.MainButton.isVisible) {
      WebApp.MainButton.hide();
    }

    if (!WebApp.BackButton.isVisible) {
      WebApp.BackButton.show();
    }

    WebApp.BackButton.onClick(handlenBackButtonClick)

    getWallets().then((wallets) => {
      const wallet = wallets.find((e) => Number(e.default) === 1);
      setWalletDefault(wallet);
      getBalance(wallet?.address);
      if (wallet?.address) {
        QRCode.toDataURL(wallet?.address)
          .then((url) => {
            setQrCodeUrl(url);
          })
          .catch((err) => {
            console.error('Erro ao gerar QR Code', err);
          });
      }
    });

    return () => {
      WebApp.BackButton.offClick(handlenBackButtonClick);
    };

  }, [handlenBackButtonClick]);


  return (
    <div className='profile app'>
      <header>
        <div className="title">
          <h4 className="tertiary">Your Balance</h4>
          <div className='balance'>
            <h3 className="btc-heading" title="Wallet value">{balance}</h3>
          </div>
        </div>
        <div className='avatar'>
          <IconButton aria-label="CropFreeIcon" onClick={() => readQRCode()} >
            <CropFreeIcon />
          </IconButton>
        </div>
      </header>
      <div className="wallet-container">
        <ul id="coin-list">
        </ul>
        {qrCodeUrl && (
          <div className='card-qrcode'>
            <img src={qrCodeUrl} alt="QrCode" />
          </div>
        )}
        {walletDefault && (
          <div className="copy-address">
            <div className='address'>{walletDefault?.address}</div>
            <div>
              <IconButton aria-label="Copy" onClick={() => copyToClipboard(walletDefault?.address)}>
                <ContentCopyIcon />
              </IconButton>
            </div>
          </div>
        )}
        {walletDefault && !Boolean(showPrivateKey) && (
          <div className="settings">
            <List>
              <ListItem onClick={handleShowPrivateKey}>
                <ListItemIcon>
                  <KeyIcon />
                </ListItemIcon>
                <ListItemText primary="Export Private Key" />
              </ListItem>
            </List>
          </div>
        )}
        {walletDefault && Boolean(showPrivateKey) && (
          <div className="copy-address">
            <div className='address' style={{ fontSize: 5 }}>{walletDefault?.privateKey}</div>
            <div>
              <IconButton aria-label="Copy" onClick={() => copyToClipboard(walletDefault?.privateKey)}>
                <ContentCopyIcon />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Profile;
