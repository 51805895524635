import React from 'react';
import { useNavigate } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';
import { getWallets } from '@dapp/services/indexedDB';
import '@dapp/assets/css/onboarding.css';


function Onboarding() {
  const navigate = useNavigate();


  const creation = React.useCallback(async () => {

    if (!WebApp.MainButton.isProgressVisible) {
      WebApp.MainButton.setParams({ is_active: false, color: "#555", });
      WebApp.MainButton.showProgress();
      WebApp.MainButton.disable();
    }

    const wallets = await getWallets();
    if (wallets.length === 0) {
      navigate("/wallet-creation");
    } else {
      navigate("/home");
    }

  }, [navigate]);


  React.useEffect(() => {

    if (WebApp.MainButton.isProgressVisible) {
      WebApp.MainButton.hideProgress();
    }


    if (!WebApp.isExpanded) {
      WebApp.expand();
    }

    if (WebApp.BackButton.isVisible) {
      WebApp.BackButton.hide();
    }


    if (!WebApp.MainButton.isVisible) {
      WebApp.MainButton.show();
      WebApp.MainButton.setParams({ text: "CONTINUE" });
      WebApp.MainButton.onClick(creation);
    }

    return () => {
      WebApp.MainButton.offClick(creation);
      WebApp.MainButton.hideProgress();
      WebApp.MainButton.enable();
      WebApp.MainButton.hide();
    };
  }, [creation]);


  return (
    <div className="onboarding container">
      <div>
        <img className="welcome" src="/assets/img/003.webp" alt="Welcome" />
      </div>
      <div>
        <h1>Blast Roulette</h1>
      </div>
      <div>
        <p>Get ready for a unique and exciting experience</p>
      </div>
    </div>
  );
}

export default Onboarding;
