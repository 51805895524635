import React from 'react';
import CardHeader from '@mui/material/CardHeader';
import { Grid } from '@mui/material';

import { numbers } from '@dapp/constants';
import { calcularRotateZ } from '@dapp/utils';

import MenuTokens from '@dapp/components/MenuTokens';
import { ChoiceTable } from '@dapp/components/ChoiceTable';
import Balance from '@dapp/components/Balance';


export default function Roulette(props) {
  const [yourbet, setYourbet] = React.useState(null);
  const [resultVisible, setResultVisible] = React.useState(false);
  const innerRef = React.useRef(null);

  React.useEffect(() => {
    if (props?.numberDrawn) {
      const angle = calcularRotateZ(props?.numberDrawn);
      const element = innerRef.current;
      element?.classList.remove('stop-result');
      void element.offsetWidth;
      element.style.setProperty('--rotation-angle', `${angle}deg`);
      element.classList.add('stop-result');
      setResultVisible(false);
      const timeout = setTimeout(() => {
        setResultVisible(true);
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [props?.numberDrawn,]);

  React.useEffect(() => {
    if (!props?.running && props?.numberDrawn && resultVisible) {
      innerRef.current.classList.add('stop-result');
    }
  }, [props?.numberDrawn, props?.running, resultVisible]);

  return (
    <Grid className='roulette'>
      <CardHeader
        sx={{ height: 40 }}
        subheader={<MenuTokens disabled={false} />}
        action={<Balance balanceUSD={props?.balanceUSD} balance={props?.balance} onClick={props?.onClickBalance} />}
      />
      <div className="card-table">
        <div className="plate" id="plate">
          <ul className={`inner ${props?.running && "active"} ${(!props?.running && props?.numberDrawn) && "stop-result"}`} ref={innerRef}>
            {numbers.map(number => (
              <li className="number" key={number}>
                <label><input type="radio" name="pit" /><span className="pit">{number}</span></label>
              </li>
            ))}
          </ul>
          <div className={`data ${(resultVisible && !props?.running) ? "reveal" : ""}`}>
            <div className="data-inner">
              <div className="mask"></div>
              <div className={`result`} style={{ backgroundColor: props?.colorDrawn }} key={props?.colorDrawn}>
                <div className="result-number">{props?.numberDrawn}</div>
                <div className="result-color">{props?.colorDrawn}</div>
              </div>
            </div>
          </div>
        </div>
        <ChoiceTable
          makeBet={(e) => { setYourbet(e); props.yourBet(e) }}
          bet={yourbet}
          onClickMax={props?.onClickMax}
          qtySelected={props?.yourBetAmount}
          selectQty={props?.setYourBetAmount}
          errorSelectQty={""}
          helperText={props?.errorSelectQtyText}
          onIsFocused={props?.onIsFocused}
          spin={props?.spin}
          transactionHash={props?.transactionHash}
          running={props?.running}
          disabledSelect={props?.disabledSelect}
          estimated={props?.estimated}
        />
        <div className="my-bets">
          {/* <div className="title">Recent bets:</div> */}
          {props?.myBets.map((e) => (
            <div className={`result ${e?.color} ${Boolean(e?.won) ? 'won' : ''}`}>{e?.number}</div>
          ))}
        </div>
      </div>
    </Grid>
  );
}
