import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';
import WebApp from '@twa-dev/sdk';

import WalletCreation from '@dapp/components/WalletCreation';
import WalletVerification from '@dapp/components/WalletVerification';
import { saveWallet } from '@dapp/services/indexedDB';

function PageWalletCreation() {
  const navigate = useNavigate();
  const [mnemonic, setMnemonic] = useState(null);
  const [stage, setStage] = useState(0);
  const [userInputs, setUserInputs] = useState(['', '', '']);
  const [randomPositions, setRandomPositions] = useState(null); 
  const [dataWallet, setDataWallet] = useState(null);

  const copyToClipboard = useCallback(() => {
    const words = mnemonic.split(' ').map((word, index) => `${index + 1}. ${word}\n`).join('');
    navigator.clipboard.writeText(words).then(() => {
      WebApp?.showAlert("Recovery phrase copied to clipboard");
    }).catch(() => {
      WebApp?.showAlert("Failed to copy the recovery phrase");
    });
  }, [mnemonic]);


  const verifyWords = useCallback(() => {
    const words = mnemonic.split(' ');
    const isCorrect = randomPositions.every((pos, index) => words[pos] === userInputs[index].trim());
    WebApp?.showAlert(isCorrect ? "OK" : "ERROR");
  }, [mnemonic, randomPositions, userInputs]);

  const handleInputChangeWalletVerification = (index, word) => {
    const newInputs = [...userInputs];
    newInputs[index] = word;
    setUserInputs(newInputs);
  };


  const handleContinueWalletVerification = useCallback(async () => {
    try {
      if (!Boolean(WebApp.MainButton.isProgressVisible)) {
        WebApp.MainButton.showProgress();
        WebApp.MainButton.disable();
        if (dataWallet?.address && dataWallet?.privateKey) {
          await saveWallet({
            address: dataWallet?.address,
            privateKey: dataWallet.privateKey,
            default: 1,
          })

          WebApp.showConfirm("Confirm that you have saved your recovery words in a safe place", ((e) => {
            if (e) {
              navigate("/home");
            }
          }));

        }
      }
    } catch (error) {
      console.error(error.message);
      WebApp?.showAlert(error.message);
    }
  }, [dataWallet, navigate]);

  const handleMainButtonClick = useCallback(() => {
    if (stage === 0) {
      handleContinueWalletVerification();
    } else if (stage === 1) {
      verifyWords();
    }
  }, [stage, handleContinueWalletVerification, verifyWords]);


  useEffect(() => {
    if (!WebApp.BackButton.isVisible) {
      WebApp.BackButton.show();
      WebApp.BackButton.onClick(() => {
        navigate("/");
      });
    }

    WebApp.MainButton.setParams({ color: "#007aff", text: stage === 0 ? "CONTINUE" : "VERIFY" });
    WebApp.MainButton.show();
    WebApp.MainButton.onClick(handleMainButtonClick);

    return () => {
      WebApp.MainButton.offClick(handleMainButtonClick);
      WebApp.MainButton.hide();
      WebApp.MainButton.hideProgress();
      WebApp.MainButton.enable();
    };
  }, [navigate, stage, handleMainButtonClick]);


  useEffect(() => {
    const createWallet = async () => {
      if (!mnemonic && WebApp.initDataUnsafe.user.id) {
        const wallet = ethers.Wallet.createRandom();
        setDataWallet(wallet);
        setMnemonic(wallet.mnemonic.phrase);
      }
    };
    createWallet();
  }, [mnemonic]);

  return (
    <React.Fragment>
      {mnemonic && Number(stage) === 0 && (
        <WalletCreation
          mnemonic={mnemonic}
          onCopyToClipboard={copyToClipboard}
          onClickContinue={handleContinueWalletVerification}
        />
      )}

      {mnemonic && Number(stage) === 1 && (
        <WalletVerification
          mnemonic={mnemonic}
          userInputs={userInputs}
          randomPositions={randomPositions}
          onInputChange={handleInputChangeWalletVerification}
        />
      )}
    </React.Fragment>
  );
}

export default PageWalletCreation;
