import React from 'react';
import { Grid, IconButton, } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import '@dapp/assets/css/wallet.css';


function WalletCreation(props) {
  return (
    <Grid className="wallet-creation container">
      <h1>Creating a wallet</h1>
      <img className="security" src="assets/img/security.gif" alt="security" />
      <Grid container padding={0.4}>
        <Grid item xs={12}>
          <p><strong>Recovery phrase:</strong></p>
        </Grid>
        <Grid item xs={12} mb={2}>
          <div className="mnemonic-container">
            {props?.mnemonic.split(' ').map((word, index) => (
              <span
                key={index}
                className={`mnemonic-word`}
              >
                {index + 1}.{word}
              </span>
            ))}
          </div>
          <p className="words-safe">
            Keep these words safe. Only with them will you be able to recover or import your wallet.
            <br></br>
            We do not store your wallet information on any server. Your wallet is saved locally in your browser.
          </p>

        </Grid>
        <Grid container item xs={12} justifyContent="flex-end" mb={1}>
          <IconButton onClick={props.onCopyToClipboard} style={{ width: 50 }}>
            <ContentCopyIcon color="primary" />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {/* <button className="next" onClick={props?.onClickContinue}>CONTINUE</button> */}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default WalletCreation;
