import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Divider, IconButton, ListItemSecondaryAction } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import KeyIcon from '@mui/icons-material/VpnKey';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import TWA from '@twa-dev/sdk';
import '@dapp/assets/css/settings.css';

function Settings() {
  const navigate = useNavigate();

  React.useEffect(() => {
    TWA.setHeaderColor('#7f0201');
    TWA.setBackgroundColor("secondary_bg_color");
    TWA.MainButton.hide();
    TWA.BackButton.show();
    TWA.BackButton.onClick(() => {
      navigate("/home");
    });
  }, [navigate]);

  return (
    <div className="settings container">
      <List>
        {/* Import Wallet */}
        <ListItem onClick={() => console.log('Import Wallet')}>
          <ListItemIcon>
            <ImportExportIcon />
          </ListItemIcon>
          <ListItemText primary="Import Wallet" />
          <ListItemSecondaryAction>
            <IconButton edge="end">
              <ArrowForwardIosIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />


        {/* <ListItem button onClick={() => console.log('Export Private Key')}>
          <ListItemIcon>
            <KeyIcon />
          </ListItemIcon>
          <ListItemText primary="Export Private Key" />
          <ListItemSecondaryAction>
            <IconButton edge="end">
              <ArrowForwardIosIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider /> */}

        {/* Change Network */}
        <ListItem onClick={() => console.log('Change Network')}>
          <ListItemIcon>
            <SwapHorizIcon />
          </ListItemIcon>
          <ListItemText primary="Change Network" />
          <ListItemSecondaryAction>
            <IconButton edge="end">
              <ArrowForwardIosIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />


        <ListItem onClick={() => console.log('List Wallets')}>
          <ListItemIcon>
            <AccountBalanceWalletIcon />
          </ListItemIcon>
          <ListItemText primary="List Wallets" />
          <ListItemSecondaryAction>
            <IconButton edge="end">
              <ArrowForwardIosIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />

        <ListItem onClick={() => console.log('List Wallets')}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="About" />
          <ListItemSecondaryAction>
            <IconButton edge="end">
              <ArrowForwardIosIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />


        <ListItem onClick={() => console.log('Delete Account')} style={{ color: 'red' }}>
          <ListItemIcon>
            <DeleteIcon style={{ color: 'red' }} />
          </ListItemIcon>
          <ListItemText primary="Delete Account" />
          <ListItemSecondaryAction>
            <IconButton edge="end">
              <ArrowForwardIosIcon style={{ color: 'red' }} />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </div>
  );
}

export default Settings;
