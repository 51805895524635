import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import WebApp from '@twa-dev/sdk';

import { TokensOptions } from '@dapp/constants';
import { environment } from '@dapp/configs/environment';
import { SelectQty } from '@dapp/components/SelectQty';

export function ChoiceTable(props) {
  const [infoToken, setInfoToken] = React.useState(null);

  function openTransactionHash() {
    if (props?.transactionHash) {
      WebApp.openLink(`${environment.NETWORK["BLAST"].explorerUrl + "/tx/" + props?.transactionHash}`);
    }
  }

  React.useEffect(() => {
    const address = TokensOptions["BLAST"].find((e) => e.address === environment.CONTRACTS.WETH.address);
    if (address) {
      setInfoToken(address);
    }
  }, []);


  return (
    <Grid container spacing={1}>
      <Grid item xs={12} mb={3} textAlign={"center"}>
        <SelectQty
          onChange={(e) => props?.selectQty(e)}
          onClickMax={props?.onClickMax}
          qtySelected={props?.qtySelected}
          error={props?.errorSelectQty}
          helperText={props?.errorSelectQtyText}
          running={props?.running}
          onIsFocused={props?.onIsFocused}
          disabledSelect={props?.disabledSelect}
          estimated={props?.estimated}
        />
      </Grid>
      <Grid item xs={12} textAlign={"center"}>
        <ButtonGroup variant="contained" style={{ position: "relative" }}>
          <span className="caption-select-color">{("Select a color")}</span>
          <Button
            onClick={() => props?.makeBet('black')}
            style={{ backgroundColor: "#000", color: "white" }}
            className={`${props?.bet === "black" && "cor-black-selected"}`}
            disabled={Boolean(props?.disabledSelect)}>Black 1:1
            {props?.bet === "black" && <img style={{ width: infoToken?.width }} className="icone-token left" src={infoToken?.img} alt={infoToken?.name} />}
          </Button>
          <Button
            onClick={() => props?.makeBet('green')}
            style={{ backgroundColor: "#008000", color: "white" }}
            className={`${props?.bet === "green" && "cor-selected"}`}
            disabled={Boolean(props?.disabledSelect)}>Gren 1:36
            {props?.bet === "green" && <img style={{ width: infoToken?.width }} className="icone-token" src={infoToken?.img} alt={infoToken?.name} />}
          </Button>
          <Button
            onClick={() => props?.makeBet('red')}
            style={{ backgroundColor: "#ff0000", color: "white" }}
            className={`${props?.bet === "red" && "cor-selected"}`}
            disabled={Boolean(props?.disabledSelect)}>Red 1:1
            {props?.bet === "red" && <img style={{ width: infoToken?.width }} className="icone-token right" src={infoToken?.img} alt={infoToken?.name} />}
          </Button>
        </ButtonGroup>
      </Grid>

      <Grid item xs={12} md={12} textAlign={"center"}>
        {/* <button className="btn-spin" onClick={props?.spin}>SPIN</button> */}
      </Grid>
      <Grid item xs={12} md={12} textAlign={"center"}>
        {props?.transactionHash && (
          <button className="btn-view-transaction" onClick={openTransactionHash}>🕵️‍♂️ View Transaction</button>
        )}
      </Grid>
    </Grid>
  )
}