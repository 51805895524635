import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

export function SelectQty(props) {
  const [isFocused, setIsFocused] = React.useState(false);

  function onChange(event) {
    const value = event.target.value;
    const numericValue = value.replace(/[^0-9.,]/g, '');
    props?.onChange(numericValue);
  }

  function handleFocus() {
    setIsFocused(true);
  }

  function handleBlur() {
    setIsFocused(false);
  }

  React.useEffect(() => {
    props?.onIsFocused(isFocused);
  }, [isFocused, props]);

  return (
    <FormControl variant="outlined">
      <TextField
        disabled={props?.disabledSelect}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        sx={{ height: 40, width: 200, '& .MuiInputBase-root': { height: 40 } }}
        type="tel"
        label={('QTY')}
        value={props?.qtySelected}
        variant="outlined"
        autoComplete="off"
        error={props?.error}
        helperText={props?.helperText}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={props?.disabledSelect}
                onClick={() => props?.onClickMax()}
                edge="end"
                color="primary"
                style={{
                  display: "flex",
                  flexDirection: 'column'
                }}
              >
                <AddIcon />
                <span style={{ fontSize: 7, color: "#ffd602" }}>MAX</span>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {props?.estimated && (
        <span className='estimated-price'>ESTIMATED: $ {props?.estimated}</span>
      )}
    </FormControl>
  );
}
