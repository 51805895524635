import React from 'react';
import { Grid, TextField } from '@mui/material';

function WalletVerification(props) {
  const [randomPositions] = React.useState(props?.randomPositions);
  const [userInputs, setUserInputs] = React.useState(['', '', '']);

  const handleInputChange = (index, value) => {
    props.userInputs(index, value);
    const newInputs = [...userInputs];
    newInputs[index] = value;
    setUserInputs(newInputs);
  };

  return (
    <Grid className="wallet-creation container wallet-verification">
      <p>Confirm Your Recovery Words:</p>
      <Grid container padding={0.4}>
        {randomPositions.map((position, index) => (
          <Grid item xs={12} key={index} mb={2}>
            <TextField
              autoComplete="off"
              label={`Word #${position + 1}`}
              variant="outlined"
              fullWidth
              value={userInputs[index]}
              onChange={(e) => handleInputChange(index, e.target.value)}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <button className="next" onClick={props?.onClickContinue}>CONTINUE</button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default WalletVerification;
