import React from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

function Balance(props) {
  return (
    <IconButton onClick={props?.onClick}>
      <Grid container alignItems="center" className="balance">
        <AccountBalanceWalletIcon className="balance-icon" />
        <Typography variant="subtitle1" fontWeight="bold" className="balance-text">
          {props.balance}
        </Typography>
        <Typography variant="subtitle2" fontWeight="bold" className="balance-text-dolar">
          $ {props.balanceUSD}
        </Typography>
      </Grid>
    </IconButton>
  );
}

export default Balance;
