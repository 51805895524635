import { environment } from "@dapp/configs/environment";

export const red = [32, 19, 21, 25, 34, 27, 36, 30, 23, 5, 16, 1, 14, 9, 18, 7, 12, 3];

export const numbers = [
  32, 15, 19, 4, 21, 2, 25, 17, 34, 6, 27, 13, 36, 11, 30, 8, 23, 10,
  5, 24, 16, 33, 1, 20, 14, 31, 9, 22, 18, 29, 7, 28, 12, 35, 3, 26, 0
];


export const COLORS_NAME = Object.freeze({
  RED: "red",
  GREEN: "green",
  BLACK: "black"
});

export const transformacoes = {
  1: -2601, 2: -2765, 3: -2842, 4: -2784, 5: -2640,
  6: -2726, 7: -2522, 8: -2668, 9: -2562, 10: -2649,
  11: -2688, 12: -2862, 13: -2708, 14: -2582, 15: -2804,
  16: -2620, 17: -2746, 18: -2541, 19: -2794, 20: -2590,
  21: -2774, 22: -2551, 23: -2659, 24: -2630, 25: -2756,
  26: -2832, 27: -2717, 28: -2873, 29: -2531, 30: -2678,
  31: -2571, 32: -2813, 33: -2610, 34: -2736, 35: -2492,
  36: -2698, 0: -2823
};



export const TokensOptions = {
  BLAST: [
    {
      width: 33,
      height: 33,
      name: "WETH",
      address: environment.CONTRACTS.WETH.address,
      img: "/assets/network/blast/0x4300000000000000000000000000000000000004/logo.png"
    },
  ]
};

export const metaDataApp = {
  name: ""
}


