import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  typography: {
    fontFamily: '"Luckiest Guy"',
  },
  palette: {
    primary: {
      main: '#f4f4f4',
      contrastText: '#fff',
    },
    secondary: {
      main: '#44cd66',
      contrastText: '#fff',
    },
    error: {
      main: '#F44336',
      contrastText: '#fff',
    },
    text: {
      primary: '#fff',
      secondary: '#555',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          height: 40,
          '& .MuiInputBase-root': {
            height: 40,
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#f4f4f4',
            },
            '&:hover fieldset': {
              borderColor: '#f4f4f4',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#f4f4f4',
            },
          },
          '& .MuiInputLabel-root': {
            fontSize: 13,
            color: '#fff',
          },
          '& .MuiInputLabel-shrink': {
            fontSize: 13,
            color: '#fff',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          height: 40,
          display: 'flex',
          alignItems: 'center',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f4f4f4',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f4f4f4',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f4f4f4',
          },
          '& .MuiSelect-icon': {
            color: '#fff',
          },
        },
      },
      defaultProps: {
        MenuProps: {
          PaperProps: {
            sx: {
              backgroundColor: '#000000ba',
              color: '#fff',
            },
          },
        },
      },
    },
  },
});

const darkTheme = createTheme({
  palette: {
    primary: {
      main: '#64B5F6',
    },
    secondary: {
      main: '#FFA726',
    },
    error: {
      main: '#EF5350',
    },
    text: {
      primary: '#fff',
      secondary: '#BBDEFB',
    },
    background: {
      default: '#212121',
      paper: '#424242',
    },
  },
});

export { lightTheme, darkTheme };
