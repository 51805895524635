import { ethers } from 'ethers';
import Web3 from 'web3';
import axios from "axios";

import { environment } from '@dapp/configs/environment';


export async function allowance(_contract, _network, x_address, y_address) {
  try {
    const web3 = new Web3(environment.NETWORK[_network].rpcUrl);
    const contract = new web3.eth.Contract(environment.CONTRACTS[_contract].abi, environment.CONTRACTS[_contract].address);
    const allowanceWei = await contract.methods.allowance(x_address, y_address).call();
    const allowanceEther = web3.utils.fromWei(allowanceWei, "ether");
    return Number(allowanceEther);
  } catch (error) {
    return { status: 500, message: error.message }
  }
}



export async function approve(_network, value, address, privateKey, _contract) {
  try {
    const web3 = new Web3(environment.NETWORK[_network].rpcUrl);
    const amount = web3.utils.toWei(value, "ether");
    const contract = new web3.eth.Contract(environment.CONTRACTS[_contract].abi, environment.CONTRACTS[_contract].address);
    const gasPrice = await web3.eth.getGasPrice();
    const account = web3.eth.accounts.privateKeyToAccount(privateKey);
    web3.eth.accounts.wallet.add(account);
    web3.eth.defaultAccount = account.address;
    const tx = await contract.methods.approve(address, amount).send({ from: account.address, gas: 300000, gasPrice });
    return { status: true, tx };
  } catch (error) {
    return { status: false, message: error.message };
  }
}


export const web3GetBalance = async (_network, _contract, _address) => {
  try {
    const web3 = new Web3(environment.NETWORK[_network].rpcUrl);
    const contract = new web3.eth.Contract(environment.CONTRACTS[_contract].abi, environment.CONTRACTS[_contract].address);
    const e = await contract.methods.balanceOf(_address).call();
    const balanceFormatted = (Number(e) / Math.pow(10, 18)).toFixed(6);
    return { status: 200, balance: balanceFormatted }
  } catch (error) {
    return { status: 500, message: error.message }
  }
}


export async function getUsdEth() {
  try {
    const response = await axios.get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD");
    return response?.data?.USD;
  } catch (error) {
    console.error("ETH:", error.message);
  }
}


export function encryptAndSavePrivateKey(privateKey, password) {
  const web3 = new Web3();
  web3.eth.accounts.wallet.decrypt(privateKey, password);

}

export async function loadAndDecryptPrivateKey(password) {
  const encryptedJson = localStorage.getItem(environment.COOKIES.ENCRYPTED);
  if (encryptedJson) {
    return ethers.Wallet.fromEncryptedJson(encryptedJson, password);
  }
  return null;
}

