import CryptoJS from 'crypto-js';

import { COLORS_NAME, transformacoes } from "@dapp/constants";

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function getNumbermBet(name) {
  if (COLORS_NAME.RED === name) {
    return 0;
  } else if (COLORS_NAME.BLACK === name) {
    return 1;
  }
  return 2;
}


export function calcularRotateZ(number) {
  const a = transformacoes[number];
  return a;
}


export function getRandomPositions() {
  const positions = new Set();
  while (positions.size < 3) {
    positions.add(Math.floor(Math.random() * 12));
  }
  return Array.from(positions);
}


export function encryptPrivateKey(privateKey, encryptionKey) {
  return CryptoJS.AES.encrypt(privateKey, encryptionKey).toString();

}


export function decryptPrivateKey(encryptedData, encryptionKey) {
  return CryptoJS.AES.decrypt(encryptedData, encryptionKey).toString(CryptoJS.enc.Utf8);

}