import { openDB } from 'idb';

const getDB = async () => {

  return openDB('blastroulette', 2, {
    upgrade(db, oldVersion, newVersion) {
      console.log(`Upgrading database from version ${oldVersion} to ${newVersion}`);

      if (!db.objectStoreNames.contains('wallets')) {
        console.log('Creating object store: wallets');
        db.createObjectStore('wallets', { keyPath: 'id', autoIncrement: true });
      }

      if (!db.objectStoreNames.contains('mybets')) {
        console.log('Creating object store: mybets');
        db.createObjectStore('mybets', { keyPath: 'id', autoIncrement: true });
      }
    },
  });
};

 
export const getMyBets = async () => {
  const db = await getDB();
  return await db.getAll('mybets');
};

export const saveMyBet = async (bets) => {
  const db = await getDB();
  await db.add('mybets', bets);
};

export const saveWallet = async (wallet) => {
  const db = await getDB();
  await db.add('wallets', wallet);
};

export const getWallets = async () => {
  const db = await getDB();
  return await db.getAll('wallets');
};

export const getWalletById = async (id) => {
  const db = await getDB();
  return await db.get('wallets', id);
};

export const deleteWallet = async (id) => {
  const db = await getDB();
  return await db.delete('wallets', id);
};
