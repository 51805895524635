import icon from "@dapp/assets/img/jeton-token.png";
import contract_abi_roulette from "@dapp/assets/contract/abi/roulette.json";
import contract_abi_weth from "@dapp/assets/contract/abi/weth.json";

const env = {
  production: {
    URL_BASE: process.env.PUBLIC_URL,
    NETWORK: {
      BNB: {
        chainId: 56,
        name: 'Binance Smart Chain',
        currency: 'BNB',
        explorerUrl: 'https://bscscan.com',
        rpcUrl: 'https://bsc-dataseed.binance.org'
      },
      BLAST: {
        chainId: 81457,
        name: 'Blast Mainnet',
        currency: 'ETH',
        explorerUrl: 'https://blastscan.io',
        rpcUrl: 'https://rpc.blast.io'
      },
    },
    META_DATA: {
      name: 'DApp Roulette',
      description: 'Spin, Win, Repeat - Experience Decentralized Thrills!',
      url: process.env.PUBLIC_URL,
      icons: [icon]
    },
    CONTRACTS: {
      ROULETTE: {
        abi: contract_abi_roulette,
        address: "0xf76ea6B6E34ecbE07Cf25944aafb12130726a228",
      },
      WETH: {
        abi: contract_abi_weth,
        address: "0x4300000000000000000000000000000000000004"
      },
    },
  },
  development: {
    URL_BASE: process.env.PUBLIC_URL,
    NETWORK: {
      BNB: {
        chainId: 97,
        name: 'Binance Smart Chain Testnet',
        currency: 'BNB',
        explorerUrl: 'https://testnet.bscscan.com',
        rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/'
      },
      BLAST: {
        chainId: 168587773,
        name: 'Blast Sepolia',
        currency: 'ETH',
        explorerUrl: 'https://testnet.blastscan.io',
        rpcUrl: 'https://sepolia.blast.io'
      },
    },
    META_DATA: {
      name: 'DApp Roulette',
      description: 'Spin, Win, Repeat - Experience Decentralized Thrills!',
      url: process.env.PUBLIC_URL,
      icons: [icon]
    },
    CONTRACTS: {
      ROULETTE: {
        abi: contract_abi_roulette,
        address: "0xf76ea6B6E34ecbE07Cf25944aafb12130726a228",
      },
      WETH: {
        abi: contract_abi_weth,
        address: "0x4200000000000000000000000000000000000023"
      },
      USDB: {
        abi: {},
        address: "0x4200000000000000000000000000000000000022"
      }
    },
  }
};

export const environment = env[process.env.REACT_APP_ENV === "production" ? "production" : "development"];
