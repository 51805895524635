import Web3 from 'web3';
import { environment } from "@dapp/configs/environment";


export const placeBet = async (_contract, _network, amountIn, color, privateKey) => {

  try {
    const web3 = new Web3(environment.NETWORK[_network].rpcUrl);
    const abi = environment.CONTRACTS.ROULETTE.abi;
    const contract_address = environment.CONTRACTS.ROULETTE.address;
    const contract = new web3.eth.Contract(abi, contract_address);
    const amount = web3.utils.toWei(amountIn, 'ether');
    const gasPrice = await web3.eth.getGasPrice();
    const account = web3.eth.accounts.privateKeyToAccount(privateKey);
    web3.eth.accounts.wallet.add(account);
    web3.eth.defaultAccount = account.address;
    const response = await contract.methods.placeBet(_contract, amount, color).send({
      from: account.address,
      gas: 500000,
      gasPrice: gasPrice
    });
    return { status: true, response };
  } catch (error) {
    console.error(error.message);
    return { status: false, message: error?.message };
  }
};


export const getTopUsers = async (_network) => {
  try {
    const web3 = new Web3(environment.NETWORK[_network].rpcUrl);
    const contract = new web3.eth.Contract(environment.CONTRACTS.ROULETTE.abi, environment.CONTRACTS.ROULETTE.address);
    const response = await contract.methods.getTopUsers(10).call();
    return response;
  } catch (error) {
    console.error(error)
  }
};


export const getBetsUser = async (_network, address) => {
  try {
    const web3 = new Web3(environment.NETWORK[_network].rpcUrl);
    const contract = new web3.eth.Contract(environment.CONTRACTS.ROULETTE.abi, environment.CONTRACTS.ROULETTE.address);
    const response = await contract.methods.getBetsByUser(address).call();
    return response;
  } catch (error) {
    console.error(error)
  }
};

