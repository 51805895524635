import React from 'react';
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import "@dapp/assets/css/app.css";
import "@dapp/assets/css/style.css";
import "@dapp/assets/css/reset.min.css";

import { lightTheme } from '@dapp/Theme';

import Home from '@dapp/app/Layouts/Home';
import Settings from '@dapp/app/Layouts/Settings';
import Onboarding from '@dapp/app/Layouts/Onboarding';
import PageWalletCreation from '@dapp/app/Layouts/WalletCreation';
import Profile from '@dapp/app/Layouts/Profile';

function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<Onboarding key="Onboarding" />} />
          <Route path="/wallet-creation" element={<PageWalletCreation key="PageWalletCreation" />} />
          <Route path="/home" element={<Home key="Home" />} />
          <Route path="/settings" element={<Settings key="Settings" />} />
          <Route path="/profile" element={<Profile key="Profile" />} />
        </Routes>
      </Router>
    </ThemeProvider>

  );
}

export default App;
