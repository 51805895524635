import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';
import Roulette from '@dapp/components/Roulette';
import { getNumbermBet, sleep } from '@dapp/utils';
import { allowance, approve, getUsdEth, web3GetBalance } from '@dapp/services/web3';
import { getMyBets, getWallets, saveMyBet } from '@dapp/services/indexedDB';
import { getBetsUser, getTopUsers, placeBet } from '@dapp/services/roulette';
import { environment } from '@dapp/configs/environment';

function Home() {

  const [spinRoulette, setSpinRoulette] = React.useState(false);
  const [numberDrawn, setNumberDrawn] = React.useState(null);
  const [colorDrawn, setColorDrawn] = React.useState(null);
  const [userData, setUserData] = React.useState(null);
  const [balance, setBalance] = React.useState(0);
  const [balanceUSD, setBalanceUSD] = React.useState(0);
  const [priceUSD, setPriceUSD] = React.useState(0);


  const [walletDefault, setWalletDefault] = React.useState(null);
  const [yourBetColor, setYourBetColor] = React.useState(null);
  const [yourBetAmount, setYourBetAmount] = React.useState(null);
  const [transactionHash, setTransactionHash] = React.useState(null);
  const [isFocused, setIsFocused] = React.useState(false);
  const [disabledSelect, setDisabledSelect] = React.useState(false);
  const [myBets, setMyBets] = React.useState([]);
  const [estimated, setEstimated] = React.useState(null);


  const audioRef = React.useRef(null);
  const audioWinRef = React.useRef(null);
  const audioLostRef = React.useRef(null);

  const navigate = useNavigate();

  const getUserData = () => {
    if (WebApp.initDataUnsafe) {
      const user = WebApp.initDataUnsafe.user;
      setUserData(user);
    }
  };


  const handleYourBet = (color) => {
    if (yourBetColor && yourBetColor === getNumbermBet(color)) {
      setYourBetColor(null);
      return;
    }
    setYourBetColor(getNumbermBet(color))
  }

  const activateMainButton = useCallback((is_active) => {
    console.log({ is_active })
    if (is_active) {
      WebApp.MainButton.setParams({ color: "#c7a202", text: "SPIN", is_active: true });
      if (!WebApp.MainButton.isVisible) {
        WebApp.MainButton.show();
      }
      WebApp.MainButton.enable();
    } else {
      WebApp.MainButton.setParams({ color: "#555", is_active: false });
      WebApp.MainButton.disable();
    }
  }, [])


  const handleGetMyBets = () => {
    getBetsUser("BLAST", walletDefault?.address).then((response) => {
      console.log({ response });
    })
    // getMyBets().then((response) => {
    //   setMyBets(response?.sort((a, b) => b.id - a.id));
    // }).catch(console.error);
  }


  const bet = useCallback(async () => {
    const privateKey = walletDefault?.privateKey;
    const address = walletDefault?.address;
    if (privateKey && address) {
      try {
        setTransactionHash(null);
        setSpinRoulette(true);
        activateMainButton(false);
        WebApp.HapticFeedback.notificationOccurred("success");
        const _allowance = await allowance("WETH", "BLAST", address, environment.CONTRACTS.ROULETTE.address);
        console.log({ _allowance })
        if (Number(yourBetAmount) > Number(_allowance)) {
          const responseApprove = await approve("BLAST", Number(balance), environment.CONTRACTS.ROULETTE.address, privateKey, "WETH");
          console.log({ responseApprove })
          if (!responseApprove.status) {
            throw new Error(responseApprove.message);
          }
        }

        setDisabledSelect(true);
        const { status, response, message } = await placeBet(environment.CONTRACTS.WETH.address, "BLAST", yourBetAmount, yourBetColor, privateKey);
        if (!status) {
          throw new Error(message);
        }

        const { events, transactionHash } = response;
        const _won = events?.BetResolved?.returnValues[1];
        const _number = Number(events?.BetResolved?.returnValues[2]);
        const _color = events?.BetResolved?.returnValues[3];

        if (!_color) {
          throw new Error("ERROR NETWORK");
        }

        setSpinRoulette(false);
        setColorDrawn(_color);
        setNumberDrawn(_number);
        await sleep(5000);
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setTransactionHash(transactionHash);
        WebApp.HapticFeedback.notificationOccurred(_won ? "success" : "error");
        getBalance(walletDefault?.address);
        activateMainButton(true);
        setDisabledSelect(false);

        // console.log({ _color });
        // console.log({ _won });
        // console.log({ _number });

        if (_won) {
          audioWinRef.current.play();
        } else {
          audioLostRef.current.play();
        }

        await saveMyBet({ transaction: transactionHash, color: _color, won: _won, number: _number });
        handleGetMyBets();
      } catch (error) {
        WebApp.showAlert(error?.message);
        WebApp.HapticFeedback.notificationOccurred("error");
        setColorDrawn(null);
        setNumberDrawn(null);
        setSpinRoulette(false);
        activateMainButton(true);
        setDisabledSelect(false);
      }
    }

  }, [activateMainButton, balance, walletDefault?.address, walletDefault?.privateKey, yourBetAmount, yourBetColor])


  React.useEffect(() => {
    getUserData();
  }, []);


  React.useEffect(() => {

    if (!Boolean(WebApp.isExpanded)) {
      WebApp.expand();
    }

    if (WebApp.BackButton.isVisible) {
      WebApp.BackButton.hide();
    }


    WebApp.setHeaderColor('#7f0201');

    if (!Boolean(WebApp.isVerticalSwipesEnabled)) {
      WebApp.disableVerticalSwipes();
    }


    WebApp.SettingsButton.show();
    WebApp.SettingsButton.onClick(() => {
      navigate("/settings");
    });


    if (!Boolean(isFocused)) {
      WebApp.MainButton.onClick(bet);
      activateMainButton(true);
    }

    return () => {
      WebApp.MainButton.offClick(bet);
    };

  }, [activateMainButton, bet, isFocused, navigate]);


  const handleIsFocused = (event) => {
    console.log({ event })
    if (event) {
      WebApp.MainButton.setParams({ is_visible: false, });
      setIsFocused(true);
    } else {
      if (!Boolean(spinRoulette)) {
        WebApp.MainButton.setParams({ is_visible: true, });
        setIsFocused(false);
      }
    }
  }



  const getBalance = async (address) => {
    try {
      const { status, balance, message } = await web3GetBalance("BLAST", "WETH", address);
      if (status !== 200) {
        WebApp.showAlert(message);
      }
      setBalance(balance || 0);
    } catch (error) {
      WebApp.showAlert(error.message);
    }
  }



  const setMaxBet = () => {
    setYourBetAmount(balance);
  }


  React.useEffect(() => {
    if (!Boolean(isFocused)) {
      if (Number(balance) > 0 && Number(yourBetAmount) > 0 && yourBetColor != null && (Number(balance) >= Number(yourBetAmount))) {
        activateMainButton(true);
      } else {
        activateMainButton(false);
      }
    }
  }, [activateMainButton, balance, isFocused, yourBetAmount, yourBetColor]);


  React.useEffect(() => {
    if (yourBetAmount) {
      const _estimated = (priceUSD * yourBetAmount);
      setEstimated(_estimated.toFixed(2));
    }
  }, [priceUSD, yourBetAmount]);



  React.useEffect(() => {
    getWallets().then((wallets) => {
      const wallet = wallets.find((e) => Number(e.default) === 1);
      if (wallet) {
        setWalletDefault(wallet);
        getBalance(wallet?.address);
      }
    })
  }, []);


  React.useEffect(() => {
    getUsdEth().then((price) => {
      if (Number(balance) > 0) {
        const _price = Number(price) * Number(balance);
        setBalanceUSD(_price.toFixed(2));
        setPriceUSD(price);
      };
    }).catch(console.error);
  }, [balance]);



  // React.useEffect(() => {
  //   if (Boolean(spinRoulette)) {
  //     activateMainButton(false);
  //     setNumberDrawn(null);
  //     setColorDrawn(null);
  //     sleep(3000).then(async () => {
  //       const _number = Math.floor(Math.random() * 36);
  //       const color = red.includes(_number) ? "red" : (_number === 0 ? "green" : "black");
  //       setColorDrawn(color);
  //       setNumberDrawn(_number);
  //       await sleep(5000);
  //       setSpinRoulette(false);
  //       WebApp.HapticFeedback.notificationOccurred("warning");
  //       WebApp.MainButton.setParams({ is_active: true, });
  //       WebApp.MainButton.setParams({ color: "#c7a202", })
  //     });
  //   }
  // }, [spinRoulette]);


  React.useEffect(() => {
    getTopUsers("BLAST").then((top10) => {
      console.log({ top10 });
    }).catch(console.error)

    handleGetMyBets();


  }, []);



  return (
    <>
      <audio ref={audioRef} src="/assets/audio/girando.mp3" style={{ display: 'none' }} />
      <audio ref={audioWinRef} src="/assets/audio/ganhou.mp3" style={{ display: 'none' }} />
      <audio ref={audioLostRef} src="/assets/audio/perdeu.mp3" style={{ display: 'none' }} />
      <Roulette
        balanceUSD={balanceUSD}
        disabledSelect={disabledSelect}
        onClickBalance={() => { navigate("/profile") }}
        balance={balance}
        running={spinRoulette}
        numberDrawn={numberDrawn}
        colorDrawn={colorDrawn}
        onIsFocused={handleIsFocused}
        spin={bet}
        yourBet={handleYourBet}
        yourBetAmount={yourBetAmount}
        setYourBetAmount={(e) => setYourBetAmount(e)}
        onClickMax={setMaxBet}
        transactionHash={transactionHash}
        myBets={myBets}
        estimated={estimated}
      />
    </>

  );
}

export default Home;
