import * as React from 'react';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { TokensOptions } from '@dapp/constants';
import { environment } from '@dapp/configs/environment';

export default function MenuTokens(props) {
  function select(event) {
    const token = event.target.value;
    // Adicione lógica para manipular a seleção
  }

  return (
    <FormControl fullWidth variant="outlined" sx={{ width: 140, height: 20 }}>
      <Select
        className="select-token-menu"
        defaultChecked={environment.CONTRACTS.WETH.address}
        defaultValue={environment.CONTRACTS.WETH.address}
        onChange={select}
        disabled={Boolean(props?.disabled)}
        label="Crypto"
        size="small"
        sx={{
          '& .MuiSelect-select': {
            padding: 0,
            border: 'none',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& .MuiSelect-icon': {
            color: 'white',
          },
        }}
      >
        {TokensOptions["BLAST"].map((token) => (
          <MenuItem key={token.name} value={token.address} sx={{ fontSize: 11 }}>
            <Grid container alignItems="center" spacing={1} className='roulette-select-token'>
              <Grid item>
                <img width={token.width} src={token.img} alt={token.name} />
              </Grid>
              <Grid item>
                {token.name}
              </Grid>
            </Grid>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
